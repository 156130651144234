import React from 'react'
import { FaBars } from 'react-icons/fa'

import { Link } from 'react-router-dom'

export default function Navbar() {
  const [showLinks, setShowlinks] = React.useState(false)

  function Displaylinks() {
    setShowlinks(!showLinks)
  }
  return <nav>
    <div className="nav-center">
      <div className="nav-header">
        <Link to="/">
        <h2 className="homeTag">Portfolio</h2>
        </Link>
        <button 
            className= {`${showLinks ? "nav-toggle change-navColor" : "nav-toggle"}`} 
            onClick={Displaylinks}>
          <FaBars />
        </button>
      </div>
      <div className= {`${showLinks ? "links-container show-container" : "links-container"}`}>
        <ul className="links">
          <li>
            <Link to="/">home</Link>
          </li>
          <li>
            <Link to="/project">projects</Link>
          </li>
          <li>
            <Link to="/contact">contact me</Link>
          </li>
        </ul>
      </div>
      
    </div>
  </nav>
}

