import React from 'react'

import fact from "./fuct.png"
import game from "./game.png"
import furn from "./furnica.png"
import quest from "./quest.png"

import { Link } from 'react-router-dom'

export default function Project() {
   
    return (
        <div className='bg-div'>
            <div className="project-links">
                <div className="project-maindiv">
                    <div className="project-subdiv1">
                        <Link to="../pro-folder/tours/travelApp" className="proLink">
                            <img src={fact} alt="not found" className='linkPhoto'/>
                            <h3>Global Facts</h3>
                            <div className="openBtn-div">
                                <button className="openBtn">Open</button>
                            </div>
                            
                        </Link>
                    </div>
                </div>
                <div className="project-maindiv">
                    <div className="project-subdiv2">
                        <Link to="../pro-folder/TYPING/typingApp" className="proLink">
                            <img src={game} alt="not found" className='linkPhoto'/>
                            <h3>TypingSpeed Game</h3>
                            <div className="openBtn-div">
                                <button className="openBtn">Open</button>
                            </div>

                        </Link>
                    </div>
                </div>
                <div className="project-maindiv">
                    <div className="project-subdiv3">
                        <Link to="../pro-folder/CART/Cart-App" className="proLink">
                            <img src={furn} alt="not found" className='linkPhoto'/>
                            <h3>Shop Today</h3>
                            <div className="openBtn-div">
                                <button className="openBtn">Open</button>
                            </div>
                        </Link>
                    </div>
                </div>
                <div className="project-maindiv">
                    <div className="project-subdiv4">
                        <Link to="../pro-folder/QUIZ/structure" className="proLink">
                            <img src={quest} alt="not found" className='linkPhoto'/>
                            <h3>Short Quiz</h3>
                            <div className="openBtn-div">
                                <button className="openBtn">Open</button>
                            </div>
                        </Link>
                    </div>
            </div>
        </div>

        </div>
    )
}