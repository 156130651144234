import arm from "./images/armchair.jpg"
import din from "./images/dining.jpeg"
import master from "./images/master.jpeg"



export default [
  {
    id: 1,
    title: 'Armchair set',
    price: 895.99,
    img: arm,
    amount: 1,
  },
  {
    id: 2,
    title: "Master-chair",
    price: 499.99,
    img: master,
    amount: 1,
  },
  {
    id: 3,
    title: "Dining Table",
    price: 299.99,
    img: din,
    amount: 1,
  },
];
