import React from 'react'

import ferct from "./ferct.png"
import game from "./game.png"
import furnica from "./furnica.png"
import quest from "./quest.png"

import { Link } from 'react-router-dom'
import { FaAngleDoubleRight, FaAngleDoubleLeft } from 'react-icons/fa'

export default function HmProjects() {
    const [imgNumber, setImgnumber] = React.useState(1)

    const selectDiv = document.querySelectorAll(".home-maindiv")
    
    function NextImg() {
        setImgnumber(prevImg => prevImg + 1) 
    }

    function PrevImg() {
        setImgnumber(prevImg=> prevImg - 1)
    }
    
    React.useEffect(()=> {
        if(imgNumber > selectDiv.length) {
            setImgnumber(prevImg=> 1)
        } else if (imgNumber === 0) {
            setImgnumber(prevImg=> selectDiv.length)
        }
        const timeOut = setTimeout(()=> setImgnumber(prevImg => prevImg + 1), 3000)

        return ()=> {
            clearTimeout(timeOut)
        }
        
    }, [imgNumber])

    const trans1 = {
        // transform: `translateX(-${(imgNumber - 1) * 500}px)`
        transform: `translateX(-${(imgNumber - 1) * 100}%)`
    }

    return (
        <div className="homeDiv">

            {/* PROJECT SECTION */}
            <div className="recent-projects">
                <h2 className="project-title">Recent <span className="rp-span">Projects</span></h2>
                <p>Check out these amazing projects. Explore their functionality and interactiveness</p>
            </div>

            <div className="div-display">
                
                <div className="home-links" >
                    <div className="home-maindiv " style={trans1}>
                        <div className="home-subdiv1 subdivP">
                            <Link to="../pro-folder/tours/travelApp">
                                <img src={ferct} alt="not found" className='home-photo'/>
                                <h3 className="hm-pro-h3">Fascinating Facts</h3>
                                <button className="home-maindivBtn">Open</button>
                                
                            </Link>
                        </div>
                    </div>

                    <div className="home-maindiv " style={trans1}>
                        <div className="home-subdiv2">
                            <Link to="../pro-folder/TYPING/typingApp">
                                <img src={game} alt="not found" className='home-photo'/>
                                <h3 className="hm-pro-h3">Typing Game</h3>
                                <button className="home-maindivBtn">Open</button>

                            </Link>
                        </div>
                    </div>

                    {/* <<<<<<<<<<<<<<END>>>>>>>>>>>>>>>>>>> */}
                    <div className="home-maindiv" style={trans1}>
                        <div className="home-subdiv3">
                            <Link to="../pro-folder/CART/Cart-App">
                                <img src={furnica} alt="not found" className='home-photo'/>
                                <h3 className="hm-pro-h3">Shop Today</h3>
                                <button className="home-maindivBtn">Open</button>


                            </Link>
                        </div>
                    </div>

                    <div className="home-maindiv" style={trans1}>
                        <div className="home-subdiv4">
                            <Link to="../pro-folder/QUIZ/structure">
                                <img src={quest} alt="not found" className='home-photo'/>
                                <h3 className="hm-pro-h3">Short Quiz</h3>
                                <button className="home-maindivBtn">Open</button>

                            </Link>
                        </div>
                    
                    </div>
                    
                    <button className="slideBtn slidePrev" onClick={PrevImg}><FaAngleDoubleLeft /></button>
                    <button className="slideBtn slideNext" onClick={NextImg}><FaAngleDoubleRight /></button>
                </div>
            </div>
        </div>
    )
}