import React from 'react'

import HmProjects from './HOME-folder/hm-projects'
import Portf from "./portfolio"
import Remarks from './HOME-folder/remarks'
import ContactForm from './HOME-folder/contact-form'

export default function Home() {
    
    return (
        <div className="homeDiv">
            <Portf />
            <Remarks />
            <HmProjects />
            <ContactForm />
            
        </div>
    )
}
















// ************************************ORIGINAL****************************




// import vacate from "./vacate.png"
// import notes from "./notes.png"
// import phones from "./phones.png"
// import quest from "./quest.png"

// import { Link } from 'react-router-dom'

// export default function Home() {
//     const [imgNumber, setImgnumber] = React.useState(1)
//     // const [timeOut, setTymeout] = React.useState("")

//     const selectDiv = document.querySelectorAll(".home-maindiv")
    
//     function NextImg() {
//         setImgnumber(prevImg => prevImg + 1) 
//         // 
        
//     }

//     function PrevImg() {
//         setImgnumber(prevImg=> prevImg - 1)
//     }
    
//     React.useEffect(()=> {
//         if(imgNumber > selectDiv.length) {
//             setImgnumber(prevImg=> 1)
//         } else if (imgNumber === 0) {
//             setImgnumber(prevImg=> selectDiv.length)
//         }
//         const timeOut = setTimeout(()=> setImgnumber(prevImg => prevImg + 1), 3000)

//         return ()=> {
//             clearTimeout(timeOut)
//         }
        

//     }, [imgNumber])

    
    

//     const trans1 = {
//         transform: `translateX(-${(imgNumber - 1) * 500}px)`

//     }

//     return (
//         <div className="homeDiv">

//             {/* PROJECT SECTION */}

//             <div className="div-display">
//                 <div className="home-links" >
//                     <div className="home-maindiv " style={trans1}>
//                         <div className="home-subdiv1">
//                             <Link to="../pro-folder/tours/travelApp">
//                                 <img src={vacate} alt="not found" className='home-photo'/>
//                                 <h2>Did you know</h2>
//                                 <button>Open</button>
                                
//                             </Link>
//                         </div>
//                     </div>
//                     <div className="home-maindiv " style={trans1}>
//                         <div className="home-subdiv2">
//                             <Link to="../pro-folder/NOTES/notesapp">
//                                 <img src={notes} alt="not found" className='home-photo'/>
//                                 <h2>Take Notes</h2>
//                                 <button>Open</button>

//                             </Link>
//                         </div>
//                     </div>
//                     <div className="home-maindiv" style={trans1}>
//                         <div className="home-subdiv3">
//                             <Link to="../pro-folder/CART/Cart-App">
//                                 <img src={phones} alt="not found" className='home-photo'/>
//                                 <h2>Purchase</h2>
//                                 <button>Open</button>


//                             </Link>
//                         </div>
//                     </div>

//                     <div className="home-maindiv" style={trans1}>
//                         <div className="home-subdiv4">
//                             <Link to="../pro-folder/QUIZ/structure">
//                                 <img src={quest} alt="not found" className='home-photo'/>
//                                 <h2>Exam yourself</h2>
//                                 <button>Open</button>


//                             </Link>
//                         </div>
                    
//                     </div>
                    
//                     <button className="slideBtn slidePrev" onClick={PrevImg}>Prev</button>
//                     <button className="slideBtn slideNext" onClick={NextImg}>Next</button>
//                     {/* <div className="homeBtn"></div> */}
//                 </div>
//             </div>
//         </div>
//     )
// }