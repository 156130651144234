import React from 'react'

import Loading from "./loading"
import "./travel.css"
import data from "./data"
import MappedTours from './mappedTours'

export default function Travelapp() {
    const [loading, setLoading] = React.useState(true)
    const [tours, setTours] = React.useState([])

    function removeTour(id) {
        const newTours = tours.filter((item)=> item.id !==id)
        setTours(newTours)
    }

    function LoadTours() {
        setTours(data)
        setLoading(false)
    }
    
    React.useEffect(()=> {
        LoadTours()

    }, [])
    
    if (loading) {
        return <main>
            <Loading />
        </main>
    }
    if (tours.length === 0) {
        return <div className="main-ReloadDiv">
            <div className="ReloadDiv">
                <h2>no Facts left</h2>
                <button className="btn" onClick={LoadTours}>Reload</button>
            </div>
        </div>
    }

    return (
        <div>
            <MappedTours tours={tours} removeTour={removeTour} />
        </div>
    )

}