export default [
    {
        id: 1,
        name: "JOB",
        tittle: "Entrepreneur",
        description: "Delectus nostrum quo consequatur rerum quos! Voluptatem culpa dolore distinctio nobis iusto ipsum? Asperiores eius nobis. ipsam consectetur aliquid? Esse atque nobis sapiente necessitatibus repudiandae, similique facere eaque aliquam  Deserunt eius voluptatum et nesciunt. Odio nemo eos maxime accusantium blanditiis , possimus."
    },
    {
        id: 2,
        name: "ANN",
        tittle: "Restaurant Owner",
        description: "Totam in tenetur quo maxime culpa a debitis ipsam doloribus iste, non repellendus sapiente molestiae error corporis consequatur ullam minus, inventore nihil excepturi rerum modi nesciunt? Optio laboriosam mollitia nesciunt at earum delectus? Adipisci maxime asperiores tempora rerum non neque necessitatibus natus officiis."
    },
    {   id: 3,
        name: "DAN",
        tittle: "Marketing Officer",
        description: "Explicabo voluptatum odio ipsum voluptatibus assumenda beatae provident eaque placeat excepturi maiores quae, optio error sapiente vitae? Ad nihil magnam, deleniti aliquam voluptatibus dolore tatibus repudiandae, similique facere eaque aliquam laborum nam numquam excepturi?  Odio rem, amet sed corporis porro molestiae ."
    }
]