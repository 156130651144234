import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './app';


import { AppProvider } from './pro-folder/CART/context';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <AppProvider>
      <App />
  </AppProvider>
  // <div>
  //   <App />
  // </div>
);
