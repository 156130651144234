
import React from "react"
import { Link } from "react-router-dom"
import emailjs from '@emailjs/browser';

import {FaLinkedin, FaTwitter, FaPhone, FaEnvelope, FaGithub, FaCheckCircle} from "react-icons/fa"

export default function Contact() {

    const [isPopActive, setPop] = React.useState(false)
    const form = React.useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_xeye97r', 'template_r7q8g7e', form.current, 'UrANPg58k7c-EgKe7')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
    };

    // <<<<<<<<<<<<<<<<<<<Modifications>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

    const[checker, setChecker] = React.useState(false)
    const[emailchecker, setemailChecker] = React.useState(false)

    const[validation, setValid] = React.useState(false)
    const [responseData, setResponse] = React.useState({
        user_name: "",
        user_email: "",
        message: ""

    })
    function GrabInputs(event) {
        setResponse(prevResponse => {
            return {
                ...prevResponse,
                [event.target.name]: event.target.value
            }
        })
    }
    let validEmail = responseData.user_email.includes("@")

    function CheckInputs() {
    
        if (responseData.user_name == "") {
            // console.log("name cant be blank")
            setChecker(true)
        }
        if (responseData.user_email == "") {
            setemailChecker(true)
        } else {
            if( validEmail == false ) {
                setValid(true)
                
            } else {
                setValid(false)
            }
        }
        if (responseData.user_name == "" || responseData.user_email == "") {
            setPop(false)
        } else {
            if (validEmail == true) {
                setPop(true)
            }    
        } 
    }
   
    let inputStyle = {
        border: checker ? "2px solid red": ""
    }
    let emailStyle = {
        border: emailchecker ? "2px solid red": ""
    }
    let warningStyle = {
        color: "red",
        fontWeight: "bolder",
        letterSpacing: "2px"
        
    }

    // <<<<<<<<<<<<<<<<<<Modifications>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

// <<<<<<<<<<<<<<<DISPLACEMENT>>>>>>>>>>>>>>>>>>>>>>>
React.useEffect(()=> {
    const inputDiv = document.querySelectorAll(".input-div")
    const mysocials = document.querySelectorAll(".mysocials-div")

    const appearOptions = {
        threshold: 0.5
    };
    const appearOnScroll = new IntersectionObserver(function(entries, appearOnScroll) {
        entries.forEach((entry)=> {
            if (!entry.isIntersecting) {
                return;
            } else {
                entry.target.classList.add("appear")
                appearOnScroll.unobserve(entry.target)
            }

        })
    }, appearOptions)

    inputDiv.forEach((input)=> {
        appearOnScroll.observe(input)
    })

    mysocials.forEach((trans)=> {
        appearOnScroll.observe(trans)
    })
}, [])

    return (
        <div className="socials-mainc contactPage">
            <div className="in-touch">
                <h3>Get in <span>Touch</span></h3>
                <hr/>
            </div>
            <div className={isPopActive ? "popup pop-active" : "popup"}>
                    <FaCheckCircle  className="tick"/>
                    <h3>Thank You</h3>
                    <p className="thank-ms">Your message has been sent successfully. Thanks!</p>
                    <button className="OK-btn" onClick={()=> setPop(false)}>OK</button>
                </div>
            <form
                ref={form} onSubmit={sendEmail}
                // action=""
                // method="post" 
                
                className="socials-form">
                <div className="mysocials-div">
                    <h3 className="folio">Portfolio</h3>
                    <h4>Contact me directly:</h4>
                    <div className="phone-div">
                        <FaPhone />
                        <span> +254 707471067</span>
                    </div>
                    <div className="email-div">
                        <FaEnvelope />
                        <span>  zublonngesa@gmail.com</span>
                    </div>
                    <div>
                        <h4>Check my profiles</h4>
                        <div className="my-socials">

                            <Link to="https://github.com/nickngesa"> <FaGithub className="form-icon" /></Link>
                            <Link to="https://www.linkedin.com/in/nickson-nyongesa-77169714b"> <FaLinkedin className="form-icon" /> </Link>
                            <FaTwitter className="form-icon" />
                        </div>
                    </div>

                </div>

                <div className="input-div">
                    <input 
                        type="text"
                        placeholder="Enter Name"
                        name="user_name"
                        onChange={GrabInputs}
                        style={inputStyle}
                    />
                    {checker ? <p className="redClass" style={warningStyle}>Name can't be blank</p>: ""}
                    <input 
                        type="email"
                        placeholder="Enter Email"
                        name="user_email"
                        onChange={GrabInputs}
                        style={emailStyle}

                    />
                    {emailchecker ? <p className="redClass" style={warningStyle}>Email can't be blank</p>: validation ? <p className="redClass" style={warningStyle}>Invalid email</p>: "" }
                    <textarea 
                        placeholder="Your Message"
                        name="message"
                        rows="8"

                    />
                    <button type="submit" value="Send" className="formBtn" onClick={CheckInputs}>Send Message</button>

                </div>
                
            </form>
        </div>
        
    )
}