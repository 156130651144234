import React from "react"

import Score from "./score"
import Stopwatch from "./timer"

import { useGlobalContext } from "../CART/context"

export default function SetQuest({items}) {
    const {results, setResults} = useGlobalContext()
    const [qscore, setQscore] = React.useState("")
    const [showers, setShowers] = React.useState(0)
    let realScore = 0


    let myQuestions = ""
    myQuestions =  items.map((questItem, qIndex)=> {
        const {id, q, a} = questItem

        let answers = ""
        answers = a.map((ans, aIndex)=> {
            // let qonverted = toString(correct === aIndex)
            return (
                <li className="questList">
                    <label>
                        <input 
                            type="radio" 
                            name={`question-${qIndex}`}
                            data-correct= {`${questItem.correct === aIndex}`}
                            className="radioBtn"
                            // dataset-correct= {qonverted}
                        />
                        {ans}
                    </label>
                </li>
            )
        })
        return (
            <div key={id}>
                <h3 className="theQuestion">{q}</h3>
                {answers}
            </div>
                
            
        )
    })
    function Submit4m(event) {
        event.preventDefault()
        const selectedInputs = event.target.querySelectorAll("input[type=radio]:checked")

        selectedInputs.forEach((selectedRadio)=> {
            if (selectedRadio.dataset.correct === "true") {
                console.log("correct")
                realScore += 1
            } else if (selectedRadio.dataset.correct === "false") {
                console.log("wrong")
                // realScore = realScore
            }
        })

        let percentScore = Math.floor((realScore / 10) * 100)

        setShowers(percentScore)

        if (selectedInputs.length === 0) {
                
                setQscore("Kindly select an answer!!!")
            } else {
                setQscore(`Score: ${percentScore}%`)
            }
        

        setResults(!results)
        
    }
    
    return <div className="formPosition">
        {results ? 
            <div className="timer-mainDiv">
                <div className="trial-timer">
                    <Stopwatch autoSubmit={Submit4m} />

                </div>
                <form onSubmit={Submit4m} className="displace-form">
                    <div className="quiz-description">
                        <h4>Test your <span className="keyWord">world's general knowledge</span> and have fun with your friends answering these questions.</h4>
                        <h4>You have <span className="keyWord">4-mins</span> to attempt all questions.</h4>
                        <h4> Score above 50% to display a <span className="keyWord">confetti</span>.</h4>
                    </div>
                    {myQuestions}
                    <button className="quiz-btn" type="submit" >Submit</button>
                </form>
                
            </div>
             :
            <Score displayDiv={qscore} showers={showers}/>
        }
        
    </div>
}