import React from "react"

export default function Stopwatch({autoSubmit}) {

    let [seconds, setSeconds] = React.useState(0)
    let [minutes, setMinutes] = React.useState(0)

    function Timer() {
        setSeconds(seconds += 1)
        // seconds += 1;
        if (seconds / 60 === 1) {
            // seconds = 0;
            setMinutes(minutes += 1);
            setSeconds(seconds = 0)

            if (minutes / 60 === 1) {
                setMinutes(minutes = 0);
                
            }
        }
    }
    
    // call interval
    React.useEffect(()=> {
        const mytime = setInterval(Timer, 1000);

        return ()=> clearInterval(mytime)

    }, [seconds])

    // Autosubmit form
    if (minutes === 4) {
        document.querySelector(".quiz-btn").click()
    }
    
    // Add leading zeros
    let newSeconds = `${seconds < 10 ? "0" + seconds.toString() : seconds}`
    let newMinutes = `${minutes < 10 ? "0" + minutes.toString() : minutes}`

    
    return(
       
        <div className="timerDiv">
            <h3>{newMinutes}:{newSeconds}</h3>
        </div>
    )
// end of stopwatch
}

