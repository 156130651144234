import React from 'react'

import Navbar from './Navbar'
import Home from './pages/home'
import Projects from './pages/projects'
import Contact from './pages/contact'
import Travelapp from './pro-folder/tours/travelApp'
import CartApp from './pro-folder/CART/Cart-App'
import Qnit from './pro-folder/QUIZ/structure'
import TypingGame from './pro-folder/TYPING/typingApp'

import { BrowserRouter, Routes, Route } from 'react-router-dom'

function App() {
  return (
    <>
      <BrowserRouter>
        <Navbar />
        <Routes>
          < Route path="/" element={<Home/>}/>
          
          < Route path="/project" element={<Projects/>}/>
          < Route path="/contact" element={<Contact/>}/>

          {/* projects */}
          < Route path="/pro-folder/tours/travelApp" element={<Travelapp/>}/>
          < Route path="/pro-folder/TYPING/typingApp" element={<TypingGame/>}/>
          < Route path="/pro-folder/CART/Cart-App" element={<CartApp/>}/>
          < Route path="/pro-folder/QUIZ/structure" element={<Qnit/>}/>
        </Routes>
      </BrowserRouter>
    </>
  )
}

export default App
