import React from 'react'

import questions from "./qsdata"
import SetQuest from './questForm'

export default function Qnit() {
    const [realQuest, setRealquest] = React.useState(questions)
    return (
        <div  className="main-quizDiv">
            <div className="main-subquizDiv" >
                <SetQuest items={realQuest}/>
            </div>
        </div>
    )
}

