import React from 'react'

export default function Tours({id, info,price, tag, name, image, removeTour}) {

    // <<<<<<<<<<<<<<<<<<<sliding>>>>>>>>>>>>>>>>>>>>>>>>

    React.useEffect(()=> {
        let factdiv = document.querySelectorAll(".singleFact")

        window.addEventListener("scroll", factBoxes)

        function factBoxes() {
            const triggerBottom = window.innerHeight

            factdiv.forEach((div)=> {
                const divTop = div.getBoundingClientRect().top + 200

                if (divTop < triggerBottom) {
                    div.classList.add("centered")
                } else {
                    div.classList.remove("centered")
                }
            })
        }

    }, [])


    // <<<<<<<<<<<<<<<<<<<sliding>>>>>>>>>>>>>>>>>>>>>
    
    const [readMore, setReadMore] = React.useState(false)
    function LessMore() {
        setReadMore(!readMore)
    }

    return <article className= {`singleFact ${tag}`}>
            <img src={image} alt={name}/>
            <footer className="footerDisc">
                <div className="fact-info">
                    <h4 className="factTitle">{name}</h4>
                    {/* <h4 className="fact-price">{price}</h4> */}
                </div>
                <p className="fact-description">{readMore ? info : `${info.substring(0, 200)}...`}
                    <button onClick={LessMore} className="ml-btn">
                        {readMore ? "Show less" : "Read more"}
                    </button>
                </p>
                    <button className="fact-btn" onClick={()=> removeTour(id)}>clear</button>
            </footer>

    </article>
}

