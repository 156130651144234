import React, { useState, useContext, useReducer } from 'react'
import cartItems from './furn-data'
import reducer from './reducer'

const AppContext = React.createContext()

const intialRedvalue = {
  loading: false,
  furn: cartItems,
  total: 0,
  amount: 0
}

const AppProvider = ({ children }) => {
  const [redValue, dispatch] = useReducer(reducer, intialRedvalue)

  // <<<<<<<<<QUIZ PART>>>>>>>>>>>>>>>>>
  
  const [results, setResults] = React.useState(true)

  // <<<<<<<<<QUIZ PART>>>>>>>>>>>>>>>>>
  

  function clearFurn() {
    dispatch({type: "CLEAR_CART"})
  }

  function remove(id) {
    dispatch({type: "REMOVE", payload: id})
  }
  
  function increase(id) {
    dispatch({type: "INCREASE", payload: id})
  }
  
  function decrease(id) {
    dispatch({type: "DECREASE", payload: id})
  }
  
  React.useEffect(()=> {
    dispatch({type: "GET_TOTALS"})
  }, [redValue.furn])



  return (
    <AppContext.Provider
      value={{
        ...redValue,
        clearFurn,
        remove,
        increase,
        decrease,
        results,
        setResults

      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export const useGlobalContext = () => {
  return useContext(AppContext)
}

export { AppContext, AppProvider }
