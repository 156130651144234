import React from 'react'
import Typed from 'typed.js'
import { motion } from 'framer-motion'

import { Link } from 'react-router-dom'
import bubble from "./bubble.png"

export default function Portf() {
    const spEl = React.useRef(null)
  
    React.useEffect(()=> {
        let typingEffect = new Typed(spEl.current, {
            strings: ["developer", "designer"],
            loop: true,
            typeSpeed: 100,
            backSpeed: 80,
            backDelay: 1500
        })

        return ()=> {
            typingEffect.destroy();
        }
        
    }, [])

    
    return (
        <div className="port-f">
            <div className='port-description'>
                <h3>Hello I'm</h3>
                <h1 className="nickson">Nickson <span className='the'>the</span> <span className="tytles" ref={spEl}></span></h1>
                <p>A frontend developer equiped with cutting edge skills in developing functional, interactive, appealing and user-friendly web applications</p>
                <div className="roller">
                
                    <motion.div className="roller-div2"
                    animate={{
                        rotate: [0, 200, 200, 0],
                        x: [0, 60, 60, 0, -60, -60, 0]


                    }}
                    transition={{repeat: Infinity, duration: 5}}
                    ></motion.div>

                    <motion.div className="roller-div"
                    animate={{
                        rotate: [0, 200, 200, 0],
                        x: [0, 200, 200, 0, -200, -200, 0],

                    }}
                    transition={{repeat: Infinity, duration: 5}}
                ></motion.div>
                </div>
                <div className="hire-Outerdiv">
                        <Link to="./contact">
                            <div className="hire-div"><h4 className="hire">Hire Me</h4></div>
                            
                        </Link>
                    </div>
            </div>
            <div className="bubbles">
                <img src={bubble} alt="not found"/>
                <img src={bubble} alt="not found"/>
                <img src={bubble} alt="not found"/>
                <img src={bubble} alt="not found"/>
                <img src={bubble} alt="not found"/>
                <img src={bubble} alt="not found"/>
                <img src={bubble} alt="not found"/>
                <img src={bubble} alt="not found"/>
                <img src={bubble} alt="not found"/>
                <img src={bubble} alt="not found"/>
                
            </div>
        </div>
    )

    

}