import React from 'react'
import FurnItem from './FurnItem'
import { useGlobalContext } from './context'
import { Link } from 'react-router-dom'


export default function FurnContainer() {
  const { furn, total, clearFurn } = useGlobalContext()

  
  if (furn.length === 0) {
    return (
      <section className='cart'>
        <header className="empty-furn">
          <h2>your store</h2>
          <h4 className='empty-cart'>is currently empty</h4>
          {/* <button onClick={()=> window.location.reload()} 
          className="reload-items">Load Items</button> */}
          <Link to="/project">
              <button className="reload-items">Load Items</button>
              
          </Link>
        </header>
      </section>
    )
  }
  return (
    <section className='cart'>
      <header>
        <h2>furnica store</h2>
      </header>
    
      <div>
        {furn.map((item) => {
          return <FurnItem key={item.id} {...item} />
        })}
      </div>
      
      <footer>
        <hr />
        <div className='cart-total'>
          <h4>
            total <span>${total}</span>
          </h4>
        </div>
        <button
          className='btn clear-btn'
          onClick={clearFurn}
        >
          clear box
        </button>
      </footer>
    </section>
  )
}


