import React, { useRef } from 'react'

import Confetti from "react-confetti"

// <<<<<<<<<<<<<<<<<<<TRIAL>>>>>>>>>>>>>>>>>>>>

let seconds = 0;
let minutes = 0;
let hours = 0;

// including zeros
let zeroSeconds = 0
let zeroMinutes = 0
let zeroHours = 0

let interval = null
let currentState = "stopped"

function timer() {
    seconds += 1
    if (seconds / 100 === 1) {
        seconds = 0
        minutes += 1
        if (minutes / 60 === 1) {
            minutes = 0
            hours += 1
        }
    }

    if (seconds < 10) {
        zeroSeconds = "0" + seconds.toString()
    }else {
        zeroSeconds = seconds
    }
    if (minutes < 10) {
        zeroMinutes = "0" + minutes.toString()
    }
    else {
        zeroMinutes = minutes
    }
    if (hours < 10) {
        zeroHours = "0" + hours.toString()
    }else {
        zeroHours = hours
    }

    document.getElementById("timeNum").textContent = zeroHours + ":" + zeroMinutes + ":" + zeroSeconds

}


function StopWatch() {
 if (currentState === "stopped") {
    interval = window.setInterval(timer, 10)
    
    // currentState = "started"
 }else {
    window.clearInterval(interval)
    currentState = "stopped"
 }
}
function Reset() {
    window.clearInterval(interval)
    seconds = 0
    minutes = 0
    hours = 0
    document.getElementById("timeNum").textContent = "00:00:00"
    
}

// <<<<<<<<<<<<<<<<<<END of Timer>>>>>>>>>>>>>>>>>>>>>

export default function TypingGame() {
    const [confeti, setConfeti] = React.useState(false)

    let Style1 = {
        border: "7px solid Green"
    }
    let Style2 = {
        border: "7px solid red"
    }
    let Style3 = {
        border: "7px solid yellow"
    }
    let [divStyle, setStyle] = React.useState(Style1)

    React.useEffect(()=> {

        let textArea = document.querySelector(".txArea") 
        let GivenText = document.querySelector(".chosenWord").innerHTML
        let resetButton = document.querySelector(".resetBtn")
        
        let num = 0

        function StartGame() {
            let userTextLength = textArea.value.length
            if (userTextLength === 0) {
                if (window.innerWidth > 500) {
                    StopWatch()
                }
            }
        }

        function SpellCheck() {
            let usertext = textArea.value
            let matchingString = GivenText.substring(0, usertext.length)
            
            if (usertext == GivenText) {
                // console.log("Complete match")
                setStyle(Style3)
                currentState = "started"
                StopWatch()
                setConfeti(true)

            } else {
                if (usertext == matchingString) {
                    // console.log("matching..")
                    setStyle(Style1)
                } else if(usertext != matchingString) {
                    // console.log("not matcging..")
                    setStyle(Style2)
                }
                setConfeti(false)

            }
            
        }
        function ClerTime() {
            Reset()
            textArea.value = ""
            setStyle(Style1)
            setConfeti(false)
        }

        textArea.addEventListener("keypress", StartGame)
        textArea.addEventListener("keyup", SpellCheck)
        resetButton.addEventListener("click", ClerTime)
    
    }, [])
    return (
        <div className="game-mainDiv">
            <div className="GAME game-rotate">
                {confeti ? <Confetti /> : ""}
                <div className="g-desc">
                    <h2>Typing speed game</h2>
                    <hr/>
                
                </div>
                <div className="testWords"><p className="chosenWord">july 2023 recorded the highest global average temperature ever</p>
                </div>
                <div className="typeSpace">
                    <textarea placeholder="Type the text in green to start the game..." rows="6"
                        className="txArea"
                        style={divStyle}
                    />
                </div>

                <div className="game-timer">
                    <h3 className="g-timer" id="timeNum">00:00:00</h3>
                    <div>
                        {window.innerWidth < 500 ? <button onClick={StopWatch} className="startBtn">start</button> : ""}
                        <button className="resetBtn">Reset</button>
                    </div>
                    
                </div>
            
            </div>
        </div>
    )
}

