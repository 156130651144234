
export default function reducer(redValue, action) {
    if (action.type === "CLEAR_CART") {
        return {...redValue, furn: []}
    }
    if (action.type ==="REMOVE") {
        return {...redValue, furn: redValue.furn.filter((singleItem)=> singleItem.id !== action.payload)}
    }
    if (action.type ==="INCREASE") {
        let tempCart = redValue.furn.map((CartItem) => {
            if (CartItem.id === action.payload) {
                return {...CartItem, amount: CartItem.amount + 1}
            }
            return CartItem
        })
        return {...redValue, furn: tempCart}
    }
    if (action.type ==="DECREASE") {
        let tempCart = redValue.furn.map((CartItem) => {
            if (CartItem.id === action.payload) {
                return {...CartItem, amount: CartItem.amount - 1}
            }
            return CartItem
        }).filter((filtered)=> filtered.amount !== 0)
        return {...redValue, furn: tempCart}
    }
    if (action.type === "GET_TOTALS") {

        let {total, amount} = redValue.furn.reduce((cartTotal,cartItem)=> {
            const {price, amount} = cartItem
            const itemTotal = price * amount
            cartTotal.total += itemTotal
            cartTotal.amount += amount;
            return cartTotal
        }, {
            total:0,
            amount:0
        })
        total = parseFloat(total.toFixed(2))
        return {...redValue, total, amount}

    }
    return redValue
}