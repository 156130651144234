import React from "react"

import ctData from "./client-data"

export default function Remarks() {
    
    React.useEffect(()=> {
        const sliders = document.querySelectorAll(".slide-in")
        const xtrans = document.querySelectorAll(".x-trans")

        const appearOptions = {
            threshold: 0.5
        };
        const appearOnScroll = new IntersectionObserver(function(entries, appearOnScroll) {
            entries.forEach((entry)=> {
                if (!entry.isIntersecting) {
                    return;
                } else {
                    entry.target.classList.add("appear")
                    appearOnScroll.unobserve(entry.target)
                }

            })
        }, appearOptions)

        sliders.forEach((slider)=> {
            appearOnScroll.observe(slider)
        })

        xtrans.forEach((trans)=> {
            appearOnScroll.observe(trans)
        })
    }, [])
        let mappRm = ctData.map((singlerm)=> {
            const {name, tittle, description, id} = singlerm
            return (
                <div className="rm-all slide-in" key={id}>
                    <div className="rm-name">
                        <div><h3>{name}</h3></div>    
                    </div>
                    <h3 className="rm-tittle">{tittle}</h3>
                    <p>{description}</p>
                </div>
            )
        })
    return (
        <div className="remarks-container">
            <h4 className="testimony x-trans">TESTIMONIALS</h4>
            <h3 className="get x-trans">Get <span className="except">Exceptional work</span>  from me </h3>
            <div className="remarks">
                {mappRm}
            </div>
        </div>

    )
}