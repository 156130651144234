

export default [
  
  {
    id: 1,
    q: "1) What country has pyramids older than Egypt?",
    a: [
        "Israel",
        "Libya",
        "Sudan",
        "Guatemala"
    ],
    correct: 2
  },
  {
    id: 2,
    q: "2) Which year was Google launched?",
    a: [
        "1998",
        "2005",
        "2001",
        "2003"

    ],
    correct: 0
  },
  {
    id: 3,
    q: "3) Name the smallest country in the world",
    a: [
        "Seychelles",
        "Vatican city",
        "Singapore",
        "Mauritius"
    ],
    correct: 1
  },
  {
    id: 4,
    q: "4) What country has won the most world cups?",
    a: [
        "France",
        "Germany",
        "Argentina",
        "Brazil"
    ],
    correct: 3
  },
  {
    id: 5,
    q: "5) What is the name of the driest continent on Earth?",
    a: [
        "Asia",
        "Africa",
        "Antarctica",
        "South America"
    ],
    correct: 2
  },
  {
    id: 6,
    q: "6) Which of the following countries has the highest population density?",
    a: [
        "Nigeria",
        "India",
        "U.S.A",
        "China"
    ],
    correct: 1
  },
  {
    id: 7,
    q: "7) How many countries are there in the United Kingdom?",
    a: [
        "2",
        "3",
        "4",
        "5"
    ],
    correct: 2
  },
  {
    id: 8,
    q: "8) In what country was Elon Musk born?",
    a: [
        "U.S.A",
        "South Africa",
        "France",
        "Canada"
    ],
    correct: 1
  },
  {
    id: 9,
    q: "9) Which country awards Nobel Price?",
    a: [
        "Greece",
        "Switzerland",
        "U.S.A",
        "Sweden"
    ],
    correct: 3
  },
  {
    id: 10,
    q: "10) Choose the only continent in the world without a desert",
    a: [
        "North America",
        "Europe",
        "South America",
        "Antarctica"
    ],
    correct: 1
  }
  
];
