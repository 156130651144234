import React from 'react'

import Tours from './tours'

export default function MappedTours({tours, removeTour}) {
    return (
        <div className="main-FactsDiv">
            <section>
                <div className="main-title">
                    <h2>Amazing Facts</h2>
                
                </div>
                <div className="the-facts">
                    {tours.map((onetour)=> {
                        return <Tours key={onetour.id} {...onetour} removeTour={removeTour} />
                    })}
                </div>
            </section>
        </div>
    )
}
