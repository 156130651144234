import React from "react"

import Confetti from "react-confetti"

import { useGlobalContext } from "../CART/context"


export default function Score({displayDiv, showers}) {
    const {results, setResults} = useGlobalContext()

    function ChangeRslt(){
        setResults(!results)
    }
    return (
        <div className="scoreDiv">
            {showers > 50 ? <Confetti /> : ""}
            <div className="score-subDiv">
                <h3>{displayDiv}</h3>
                <button onClick={ChangeRslt} className="scoreBtn">Back to Quiz</button>
            </div>
        </div>
    )
}