import photo1 from "./images/Turkana.jpg"
import photo2 from "./images/golden4.jpeg"
import photo3 from "./images/amazon.png"
import photo4 from "./images/japan5.png"
import photo5 from "./images/dubai1.jpg"


import {nanoid} from "nanoid"

const turkana = "Lake Turkana is the world’s largest desert lake. While its global claim to fame is that it is the world’s largest desert lake, Lake Turkana is also Kenya’s largest lake. It is located in the north of the Great Rift Valley, and is listed as a UNESCO World Heritage Site as part of the three Lake Turkana National Parks – Sibiloi National Park, Central Island National Park and South Island National Park."
const gate = "The steel that arrived in San Francisco to build the Golden Gate Bridge was coated in a burnt red orange of primer to protect it from corrosive elements. The international orange color was not only visible in fog, but it complemented the natural topography of the surrounding hills and contrasted well with the cool blues of the bay and the the sky."
const amazon = "More than 20% of the world's oxygen is produced by the Aamazon rainforest. 25% of all western pharmaceuticals come from rainforest-based ingredients, yet less than 1% of the trees and medicinal plants in the Amazon have ever been tested by scientists"
const japan = "The National Earthquake Information Center records an average of 20,000 earthquakes every year (about 50 a day). There are, however, millions of earthquakes estimated to occur every year that are too weak to be recorded. Normally, it's not the shacking ground itself that claims lives during an earthquake. It,s the associated destruction of man-made structures and the instigation of other natural disasters such as tsunamis, avalanches and landslides "
const dubai = "A quarter of the world's cranes operate in Dubai. 85% of Dubai's population are foreign expats. The interioir of Dubai's Burj Al-Arab hotel is made of 1790 square meters of gold. Dubai has the second-highest number of 5-star hotels in the world, after London. The Palm Jumeirah is visible from Space" 

export default [
    {
        id: nanoid(),
        name: "Largest desert lake",
        info: turkana,
        image: photo1,
        price: "$1,184",
        tag: "tg-left"
    },
    {
        id: nanoid(),
        name: "The Golden Gate Bridge",
        info: gate,
        image: photo2,
        price: "$564",
        tag: "tg-right"
    },
    {
        id: nanoid(),
        name: "Amazon Rainforest",
        info: amazon,
        image: photo3,
        price: "$3,390",
        tag: "tg-left"
    },
    {
        id: nanoid(),
        name: "Earthquakes",
        info: japan,
        image: photo4,
        price: "$64",
        tag: "tg-right"
    },
    {
        id: nanoid(),
        name: "Dubai",
        info: dubai,
        image: photo5,
        price: "$64",
        tag: "tg-left"
    }
    
]